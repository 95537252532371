import React, { useState } from 'react';
export interface SatelliteOptionsInterface {
    id: string;
    name: string;
    url: string;
    subdomains: string[];
}
const layers = [
    {id: "0", name: "Satellite", url: "https://fly.maptiles.arcgis.com/arcgis/rest/services/World_Imagery_Firefly/MapServer/tile/{z}/{y}/{x}", subdomains: []},
    {id: "1", name: "Ocean", url: "https://server.arcgisonline.com/ArcGIS/rest/services/Ocean/World_Ocean_Base/MapServer/tile/{z}/{y}/{x}", subdomains: []},
    {id: "2", name: "Black", url: "https://services.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}", subdomains: ['a', 'b', 'c']},
    {id: "3", name: "Openstreetmap", url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", subdomains: ['a', 'b', 'c']},
];

const SatelliteOptions: React.FC<{ onLayerClick: (layerId: SatelliteOptionsInterface) => void }> = ({ onLayerClick }) => {
  return (
    <select id="satellite-options">
        {layers.map(layer => (
            <option className='satellite-options-layer' key={layer.id} value={layer.name} onClick={() => onLayerClick(layer)} >
                {layer.name}
            </option>
        ))};
    </select>
  );
};

export default SatelliteOptions;