import React, { useState } from 'react';

interface RangeSliderProps {
  min: number; // Valeur minimale
  max: number; // Valeur maximale
  defaultValue?: number; // Valeur par défaut, optionnelle
  onChange: (value: number) => void; // Fonction à exécuter lors de la modification de la valeur
}

const RangeSlider: React.FC<RangeSliderProps> = ({ min, max, defaultValue = 1, onChange }) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value, 10);
    setValue(newValue);
    onChange(newValue); // Appelle la fonction onChange passée en prop avec la nouvelle valeur
  };

  return (
    <input
      type="range"
      id="date-slider"
      min={min}
      max={max}
      value={value}
      className="custom-slider"
      onChange={handleChange}
    />
  );
};

export default RangeSlider;
