import React from 'react';

interface ToggleButtonDateOverviewUpProps {
    toggle: () => void; // Fonction pour gérer le basculement
    //iconSrc: string; // Chemin d'accès à l'icône du bouton
    //altText: string; // Texte alternatif pour l'icône
}

const ToggleButtonDateOverviewUp: React.FC<ToggleButtonDateOverviewUpProps> = ({ toggle }) => {
    return (
        <button onClick={toggle} id="toggle-date-overview-up" className="toggle-button-date-up">
      </button>
    );
};

export default ToggleButtonDateOverviewUp;