import layersWMS from '../../data/layers/layersconfig.json';
import layersEOdyn from '../../data/layers/layersconfig_eOdyn.json';

import { LayerConfigWMS } from '../types/LayerConfigWMS';
import { LayerConfigEOdyn } from '../types/LayerConfigEOdyn';

export function getLayerConfigWMS(id: string): LayerConfigWMS | null {
    var layerConfig: LayerConfigWMS | null = null;
    layersWMS.forEach(layer => {
        if (layer.id === id) {
            console.log(layer)
            layerConfig = layer;
        }
    });
    return layerConfig;
}

export function getLayerConfigEOdyn(id: string): LayerConfigEOdyn | null {
    var layerConfig: LayerConfigEOdyn | null = null;
    layersEOdyn.forEach(layer => {
        if (layer.id === id) {
            console.log(layer)
            layerConfig = layer;
        }
    });
    return layerConfig;
}

export function getLayerRange(layer: LayerConfigEOdyn): Date[] | null {
    if (!layer.range || layer.range.length === 0) return null;

    const minRange = new Date(layer.range[0]);
    const maxRange = layer.range[1] ? new Date(layer.range[1]) : new Date();

    return [minRange, maxRange];
}
