import React, { useState } from 'react';

interface ToggleButtonDateLabelProps {
  // Props supplémentaires si nécessaire
}


const ToggleButtonDateLabel: React.FC<ToggleButtonDateLabelProps> = () => {
  const [isActive, setIsActive] = useState(false);

  const toggle = () => {
    setIsActive(!isActive); // Change l'état actif/inactif à chaque clic
  };


  return (
    <button onClick={toggle} id="date-label" className="small-custom-button">
      1 Day
    </button>
  );
};

export default ToggleButtonDateLabel;
