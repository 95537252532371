import React from 'react';

interface ToggleLayerSelectorProps {
    toggle: () => void; // Fonction pour gérer le basculement
}

const ToggleLayerSelector: React.FC<ToggleLayerSelectorProps> = ({ toggle }) => {
    return (
        <button onClick={toggle} id="toggle-layer-selector" className="toggle-button">
            {/*Symbole de menu hamburger */}
            &#9776;   
      </button>
    );
};

export default ToggleLayerSelector;