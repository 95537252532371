import React, { useEffect, useState } from "react";
import Keycloak from "keycloak-js";
import '../../css/Login.css';

let keycloak: Keycloak.KeycloakInstance | null = null;

const initOptions = {
    url: "https://keycloak.eodyn.com", //process.env.REACT_APP_KEYCLOAK_URL  as string,
    realm: "master", //process.env.REACT_APP_KEYCLOAK_REALM  as string,
    clientId: "ocean-vibe", //process.env.REACT_APP_KEYCLOAK_CLIENT_ID  as string,
};

const Login: React.FC = () => {
  const [keycloakInitialized, setKeycloakInitialized] = useState(false);
  const [username, setUsername] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!keycloak) {
      keycloak = new Keycloak(initOptions);

      keycloak.init({ onLoad: "login-required" })
        .then((authenticated: any) => {
          if (authenticated) {
            setKeycloakInitialized(true);
            setUsername(keycloak?.tokenParsed?.preferred_username);
            localStorage.setItem('authToken', keycloak?.token as string);
          } else {
            console.log("Not authenticated");
          }
        })
        .catch((error: any) => {
          console.error("Failed to initialize Keycloak:", error);
        });
    } else {
      setKeycloakInitialized(true);
      setUsername(keycloak.tokenParsed?.preferred_username);
    }

    // Rafraîchir le token à intervalle régulier
    const refreshInterval = setInterval(() => {
      if (keycloak) {
        keycloak.updateToken(60) // Met à jour le token s'il expire dans les 60 secondes
          .then((refreshed: boolean) => {
            if (refreshed && keycloak){
              console.log("Token was successfully refreshed");
              localStorage.setItem('authToken', keycloak.token as string);
            } else {
              console.warn("Token is still valid");
            }
          })
          .catch(() => {
            console.error("Failed to refresh token");
            keycloak?.logout(); // Déconnexion si le rafraîchissement échoue
          });
      }
    }, 60000); // Intervalle de 60 secondes

    return () => clearInterval(refreshInterval); // Nettoyage lors du démontage du composant

  }, []);

  if (!keycloakInitialized) {
    return (
      <div className="topnav">Loading...</div>
    );
  }

  return (
    <div className="topnav">
      <div className="login-container">
        <a id="kc-name">{username || 'Loading...'}</a>
        <button type="button" onClick={() => { keycloak?.logout() }}>Logout</button>
      </div>
    </div>
  );
};

export default Login;
