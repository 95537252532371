import React, { useEffect, useRef, useState } from 'react';
import * as maptalks from 'maptalks';
import { wmsDateString, getTimeFormatWMS, buildWmsUrl, min} from './Utils'
import { newVectorLayer, vectorLayerWMS, cacheVectorLayer } from './utils/VectorLayer'
import { getLayerConfigWMS, getLayerConfigEOdyn} from './utils/LayerConfig'
import {SatelliteOptionsInterface} from './SatelliteOptions';
import {LayerConfigWMS} from './types/LayerConfigWMS';
import LayerSelector from './LayerSelector';
//import { DrawTool } from 'maptalks.draw';
//const WindyLayer = require('maptalks.windylayer').WindyLayer;
import '../css/maptalks-toolbar.css';
import '../css/MapComponent.css';
//import { List } from 'echarts';
//import {WindyLayer} from "./maptalks.windylayer.js";
import LayerCache from './LayerCache';

const { FeatureLayerService } = require('maptalks.esri');


interface MapProps {
  center: [number, number];
  zoom: number;
  isOverviewVisible: boolean; // Ajouter cette prop pour contrôler la visibilité de l'aperçu de la carte
  selectedDate: Date;
  isDrawingMenuVisible: boolean;
  toggleCapturingMap: boolean;
}


const MapComponent: React.FC<MapProps & 
    { visibleLayers: string[] } & 
    { visibleSatelite: SatelliteOptionsInterface[] } & 
    { layerOrderChange: any}  & 
    { isDrawingMenuVisible: any } &
    { toggleCapturingMap: any } &
    { handleGridOnChange: any } &
    { handleReferenceOnChange: any } &
    { isDrawingTargetVisible: any } &
    { isSliderVisible: any } &
    { visibleLayersSlider: string[] }
  > = (
  { 
    center, 
    zoom, 
    isOverviewVisible, 
    visibleLayers, 
    selectedDate, 
    visibleSatelite, 
    layerOrderChange,
    isDrawingMenuVisible, // Prop pour activer/désactiver le mode dessin
    toggleCapturingMap,
    handleGridOnChange,
    handleReferenceOnChange,
    isDrawingTargetVisible,
    isSliderVisible,
    visibleLayersSlider
  }) => { 

  const mapContainer = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<maptalks.Map | null>(null);
  const [overviewControl, setOverviewControl] = useState<maptalks.control.Overview | null>(null);
  const [drawingTool, setDrawingTool] = useState(null); // État pour le contrôle de dessin

  const [zoomLevel, setZoomLevel] = useState(10);
  // Utiliser useRef pour garder une trace de l'ancienne valeur de la date
  const prevDateRef = useRef<Date>();

  const prevLayerOderRef = useRef<Object>();

  const sliderLayersIndex = useRef<string[]>([]);

  const layerCache = useRef(new LayerCache());

  // Fonction pour ajouter une couche à l'index avec typage explicite du paramètre
  function addLayerSliderToIndex(newLayer: string) {
    sliderLayersIndex.current.push(newLayer)
  }
  // Fonction pour retirer une couche de l'index avec typage explicite du paramètre
  function removeLayerSlider(layerToRemove: string) {
    const index = sliderLayersIndex.current.indexOf(layerToRemove);
    if (index > -1) {
      sliderLayersIndex.current.splice(index, 1); // Retirer l'élément du tableau
      console.log("removeLayerSlider: ",index)
    }
  }

  function removeAllLayerSlider(layerToRemove: string) {
    sliderLayersIndex.current = sliderLayersIndex.current.filter(layer => layer !== layerToRemove);
}

  // Fonction pour vérifier si une couche est dans l'index des couches du slider
  function isInLayerSliderIndex(layerId: string): boolean {
    return sliderLayersIndex.current.includes(layerId);
  }

  function countOccurrencesInLayerSliderIndex(elementToCount: string): number {
    return sliderLayersIndex.current.reduce((count: number, element: string) => {
        return element === elementToCount ? count + 1 : count;
    }, 0);
  }


  //const [drawingTool, setDrawingTool] = useState<maptalks.DrawTool | null>(null);
  // Initialisation de la carte
  useEffect(() => {
    if (!mapContainer.current) return; // Sortie précoce si le conteneur n'est pas encore disponible

      const initialMap = new maptalks.Map(mapContainer.current, {
        center: new maptalks.Coordinate(center), // Conversion du tuple en Coordinate
        zoom,
        maxZoom: 18,
        minZoom: 4,
        //zoomControl: {
        //  'position'  : 'top-left',
        //  'zoomLevel' : true
        //}
        overviewControl: false,
        //spatialReference: {
        //  projection: 'EPSG:4326'
        //},
        //baseLayer: new maptalks.WMSTileLayer('wms', {
        //  'tileSystem': [1, -1, -180, 90],
        //  'urlTemplate': 'https://ows.terrestris.de/osm/service',
        //  'crs': 'EPSG:4326',
        //  'layers': 'OSM-WMS',
        //  'styles': '',
        //  'version': '1.3.0',
        //  'format': 'image/png',
        //  'transparent': true,
        //  'uppercase': true
        //}),
        baseLayer: new maptalks.TileLayer('map', {
          // TODO CORS CROSS ORIGN for orther map
          //tileSystem: [1, -1, -180, 90],
          urlTemplate: "https://fly.maptiles.arcgis.com/arcgis/rest/services/World_Imagery_Firefly/MapServer/tile/{z}/{y}/{x}",//'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          subdomains: ['a', 'b', 'c'],
          //repeatWorld: false,
          
        }),

        //baseLayer : new maptalks.TileLayer("map", {
        //  urlTemplate : 'https://fly.maptiles.arcgis.com/arcgis/rest/services/World_Imagery_Firefly/MapServer/tile/{z}/{y}/{x}'
        //}),
        //layers : [ new maptalks.TileLayer("tile", {
        //  urlTemplate : 'https://fly.maptiles.arcgis.com/arcgis/rest/services/World_Imagery_Firefly/MapServer/tile/{z}/{y}/{x}'
        //  })
        //]
        
    });

    // Après la création de la carte, restreignez le panoramique à une certaine étendue
    //initialMap.on('moveend', () => {
    //  const x = 30
    //  const extent = new maptalks.Extent([-180+x, -80+x, 180-x, 80-x]);
    //  // Ajustez si nécessaire pour correspondre à votre utilisation
    //  if (!extent.contains(initialMap.getCenter())) {
    //    const center = initialMap.getCenter().toArray();
    //    const correctedCenter = new maptalks.Coordinate(
    //      Math.max(Math.min(center[0], extent.xmax), extent.xmin),
    //      Math.max(Math.min(center[1], extent.ymax), extent.ymin)
    //    );
    //    initialMap.panTo(correctedCenter);
    //  }
    //});


    //var noZoomLevel = new maptalks.control.Zoom({
    //  'position'  : 'top-right',
    //  'zoomLevel' : false
    //});
    //initialMap.addControl(noZoomLevel);
    //
    //var noSlider = new maptalks.control.Zoom({
    //  'position'  : 'bottom-right',
    //  'zoomLevel' : true
    //});
    //initialMap.addControl(noSlider);
    //
    //var customPosition = new maptalks.control.Zoom({
    //  'position'  : { 'bottom' : '20', 'left' : '20' },
    //  'zoomLevel' : false
    //});
    //initialMap.addControl(customPosition);
    setMap(initialMap);



    // Créer l'aperçu de la carte et le stocker dans l'état
    const overview = new maptalks.control.Overview({
      position: 'bottom-right',
      size: [200, 142],
    });
    overview.addTo(initialMap);
    setOverviewControl(overview);

    const handleZoomChange = () => {
      const newZoom = initialMap.getZoom();
      //console.log('Zoom level changed to:', newZoom);
      setZoomLevel(newZoom);
    };

    initialMap.on('zoomend', handleZoomChange);

    

    return () => {
      initialMap.remove();
    };
  }, []); // Ce useEffect ne s'exécute qu'une seule fois lors du montage du composant



  // Mise à jour du centre de la carte
  useEffect(() => {
    if (map) {
      map.setCenter(new maptalks.Coordinate(center)); // Conversion du tuple en Coordinate
    }
  }, [center, map]); // Ce useEffect s'exécute lorsque `center` ou `map` change

  // Mise à jour du zoom de la carte
  //useEffect(() => {
  //  if (map) {
  //    map.setZoom(zoom);
  //  }
  //}, [zoom, map]); // Ce useEffect s'exécute lorsque `zoom` ou `map` change

  useEffect(() => {
    if (!map) return;
    console.log("Nouveau niveau de zoom:", map.getZoom());
  }, [map, zoomLevel]); // Ce useEffect s'exécute lorsque `zoom` ou `map` change

  useEffect(() => {
    if (!map) return; // Assurez-vous que map est défini
    // Gestionnaire d'événements de zoom
    const handleZoom = () => {
      const newZoom = map.getZoom(); // Obtenez le nouveau niveau de zoom de votre instance de carte
      map.setZoom(newZoom); // Mettez à jour l'état mapZoom
      console.log("Nouveau niveau de zoom:", newZoom);
    };
  
    // Abonnez-vous à l'événement de zoom
    map.on('zoom', handleZoom); // 'zoom' est un exemple, ajustez selon la bibliothèque que vous utilisez
  
    // Nettoyage de l'effet
    return () => {
      map.off('zoom', handleZoom); // Désabonnez-vous de l'événement de zoom
    };
  }, [map]); // Incluez d'autres dépendances si nécessaire, par exemple mapZoom si vous souhaitez réagir à ses changements
  

  useEffect(() => {
    // Utiliser la prop isOverviewVisible pour afficher ou masquer l'aperçu
    if (overviewControl) {
      isOverviewVisible ? overviewControl.show() : overviewControl.hide();
    }
  }, [isOverviewVisible, overviewControl]);

  useEffect(() => {
    if (!map) return;

    console.log("DATE : "+selectedDate)

    console.log(">>> visibleLayers : "+visibleLayers)

    let onLayer = map.getLayers()
    let onLayerIds = onLayer.map(layer => layer.getId());
    console.log("onLayerIds : "+onLayerIds)
    
    //if (visibleLayers!) {
    //  onLayerIds.forEach(layerId => {
    //    map.removeLayer(layerId);
    //  });
    //}

    const layersOrder: string[] = layerOrderChange.reverse().map((layer: LayerConfigWMS) => layer.id);

    

    visibleLayers.forEach(layerId => {
      let layer: maptalks.Layer = map.getLayer(layerId);
      
      if (sliderLayersIndex.current.includes(layerId)){
        console.log("Can't add layer twice : "+layerId)
        errorMessage("Can't add layer twice : "+layerId);
        return;
      }

      if (onLayerIds.includes(layerId)) {
          // La couche ne doit pas être visible et est actuellement sur la carte, donc retirez-la
          map.removeLayer(layerId);
          console.log("Remove layer : "+layerId)
      } else {
        // La couche doit être visible mais n'est pas encore sur la carte, donc ajoutez-la
        if (layerId.includes("wms")){
          vectorLayerWMS(layerCache.current, map, layerId, selectedDate, layersOrder.indexOf(layerId))
          const layer_config = getLayerConfigWMS(layerId);
          if (layer_config !== null && layer_config !== undefined) {
            const legendUrl = layer_config.urlTemplate+'?SERVICE=WMS&REQUEST=GetLegendGraphic&VERSION='+layer_config.version+'&FORMAT=image/png&LAYER='+layer_config.layers;
            
            var d = document.getElementById('legend-img') as HTMLImageElement;
            if (d) {
              console.log("legendUrl : "+legendUrl)
              d.src = legendUrl;
            }
          }
        } else {
          // For other layers
          newVectorLayer(layerCache.current, map, layerId, selectedDate);
        }
      } 

      //console.log(visibleLayers)


    });
    
}, [map, visibleLayers]); // Réagir aux changements dans 'visibleLayers'

function getListId(data: Object | undefined){
  if (data === undefined) return;
  var listId: string[] = [];
  (data as Array<Object>).forEach((element:any) => {
    listId.push(element.id)
  });
  return listId;
}

function isSameOrder(a: string[] | undefined, b: string[] | undefined): boolean {
  if (a === undefined || b === undefined) return false;
  for (let index: number = 0; index < a.length; index++) {
    if (a[index] !== b[index]) return false;
  }
  return true;
} 

// Mise à jour des layers avec la date
useEffect(() => {
  if (!map) return;
  //if (prevDateRef.current === selectedDate) return;
  
  // TODO : résoudre layer order

  prevLayerOderRef.current = layerOrderChange;

  if (prevDateRef.current &&
    prevDateRef.current.getFullYear() === selectedDate.getFullYear() &&
    prevDateRef.current.getMonth() === selectedDate.getMonth() &&
    prevDateRef.current.getDate() === selectedDate.getDate() &&
    prevDateRef.current.getHours() === selectedDate.getHours() &&
    prevDateRef.current.getMinutes() === selectedDate.getMinutes() &&
    prevDateRef.current.getSeconds() === selectedDate.getSeconds() &&
    !isSameOrder(getListId(prevLayerOderRef.current), getListId(layerOrderChange))
  ) return;



  prevDateRef.current = selectedDate;
  console.log("PREV_DATE CHANGE : "+
  prevDateRef.current.getFullYear()+"/"+
  prevDateRef.current.getMonth()+"/"+
  prevDateRef.current.getDate()+"_"+
  prevDateRef.current.getHours()+":"+
  prevDateRef.current.getMinutes()+":"+
  prevDateRef.current.getSeconds()
)


  const layersOrder: string[] = layerOrderChange.reverse().map((layer: LayerConfigWMS) => layer.id);
  //wms
  let activeLayers = map.getLayers()
  console.log("DATE CHANGE : "+
    selectedDate.getFullYear()+"/"+
    selectedDate.getMonth()+"/"+
    selectedDate.getDate()+"_"+
    selectedDate.getHours()+":"+
    selectedDate.getMinutes()+":"+
    selectedDate.getSeconds()
  )
  console.log("Updating activeLayers : "+activeLayers)

  activeLayers.forEach((layer) => {
    const layerId: string = layer.getId();
    if (!layerId.includes("draw") && !layerId.includes("target")) {
      var layerData;
      if (layerId.includes("wms")){
        layerData = getLayerConfigWMS(layerId);
      } else {
        // Other data
        layerData = getLayerConfigEOdyn(layerId);
      }
      
      if (layerId && layerData && layerId.includes("wms")){
        map.removeLayer(layerId);
        vectorLayerWMS(layerCache.current ,map, layerId, selectedDate, layersOrder.indexOf(layerId))
        if (sliderLayersIndex.current.includes(layerId)) {
          sliderRendering(map.getLayer(layerId))
        }
        console.log("Update layer id : "+layerId);
      } else {
        map.removeLayer(layerId);
        console.log("REMOVE layer id : "+layerId);
        newVectorLayer(layerCache.current, map, layerId, selectedDate)
        if (sliderLayersIndex.current.includes(layerId)) {
          sliderRendering(map.getLayer(layerId))
        }
        console.log("UPDATE layer id : "+layerId);
      }

      
    }
    //TODO CACHE: preloading layer
    cacheVectorLayer(layerCache.current, map, "windyLayer1", selectedDate, 2)
    cacheVectorLayer(layerCache.current, map, "windyLayer2", selectedDate, 2)
  });

}, [map, selectedDate]);


useEffect(() => {
  if (!map || !visibleSatelite.length) return;

  console.log("visibleSatelite : ", visibleSatelite);

  map.setBaseLayer(new maptalks.TileLayer('map',{
    urlTemplate: visibleSatelite[0].url,
    subdomains: visibleSatelite[0].subdomains
  }));
//
  //// Supprimez toutes les couches existantes liées aux satellites pour éviter les doublons
  //const existingSatelliteLayers = map.getLayers().filter(layer => layer.getId() === visibleSatelite[0].id);
  //existingSatelliteLayers.forEach(layer => map.removeLayer(layer));
//
  //
  //// Ajoutez la nouvelle couche de satellite basée sur la sélection
  //visibleSatelite.forEach(satellite => {
  //  const satelliteLayer = new maptalks.TileLayer(`satellite-${satellite.id}`, satellite.data, {
  //    opacity: 1,
  //    // Vous pouvez ajouter plus d'options ici si nécessaire
  //  });
//
  //  map.addLayer(satelliteLayer);
  //});

}, [map, visibleSatelite]);


// order layer change
useEffect(() => {
  if (!map) return;
  let activeLayers = map.getLayers()
  if (activeLayers.length == 0) return;

  const newOrderLayer: LayerConfigWMS[] = layerOrderChange.reverse();
  
  console.log(activeLayers)
  newOrderLayer.forEach((layer: LayerConfigWMS)  => {
    if (layer.id in activeLayers) {
      console.log(layer)
      const layerChange = map.getLayer(layer.id)
      console.log("getZIndex : "+layerChange.getZIndex())
      layerChange.setZIndex(newOrderLayer.indexOf(layer))
    }
  });
  
}, [map, layerOrderChange]);

 // Écouteur pour activer/désactiver le dessin
 useEffect(() => {
  if (!map) return;


  if (map.getLayer("draw")) return;

 
  var layer = new maptalks.VectorLayer('draw').setZIndex(2000).setOpacity(0.8).addTo(map);



  //if (isDrawingMenuVisible) {

    console.log("isDrawingMenuVisible : true")
    var drawTool = new maptalks.DrawTool({
      mode: 'Point'
    }).addTo(map).disable();

    drawTool.on('drawend', function (param) {
      console.log(param.geometry);
      layer.addGeometry(param.geometry);
      drawTool.disable();
      
    });

    var items = ['Point', 'LineString', 'Polygon', 'Circle', 'Ellipse', 'Rectangle', 'FreeHandLineString', 'FreeHandPolygon'].map(function (value) {
      return {
        item: '<span class="custom-class-item">'+value+'</span>',
        click: function () {
          drawTool.setMode(value).enable();
          console.log(value)
        }
      };
    });

    var toolbar = new maptalks.control.Toolbar({
      items: [
        {
          item: "Shape",
          children: items
        },
        {
          item: "Enable",
          click: function () {
            drawTool.enable();
            layer.addTo(map);
          }
        },
        {
          item: "Disable",
          click: function () {
            drawTool.disable();
            layer.remove();
          }
        },
        {
          item: "Clear",
          click: function () {
            layer.clear();
          }
        },
        {
          item: "Close",
          click: function () {
            layer.remove()
            //layerOrderChange = [layer];
            toolbar.remove()
            drawTool.remove()
          }
        }
      ]
    }).addTo(map);

    console.log("toolbar : "+toolbar);

    console.log(map.getMenuItems())

  //} else {
  //  console.log(map.getPanels())
    //map.removeLayer('draw');
    //var toolbar = new maptalks.control.Toolbar({
    //  items: []
    //}).addTo(map).update();

    //console.log(toolbar.show())
    
    
    //console.log(map.getLayers())
  //  drawingTool.disable();
  //console.log("isDrawingMenuVisible : false")
  //}
}, [isDrawingMenuVisible, drawingTool]);

useEffect(() => {
  if (!map) return;
  map.toDataURL({
    'mimeType' : 'image/jpeg', // or 'image/png'
    'save' : true,             // to pop a save dialog
    'fileName' : 'map'         // file name
  });

}, [toggleCapturingMap]);


function query(map: maptalks.Map) {
  const layerName = "gridLayer";

  // Récupérer et supprimer la couche existante si elle est déjà présente
  const existingLayer = map.getLayer(layerName);
  if (existingLayer) {
    map.removeLayer(existingLayer);
  }

  // Créer une nouvelle couche vectorielle pour la grille
  const gridLayer = new maptalks.VectorLayer(layerName).addTo(map);
  gridLayer.setZIndex(10001); // Définir l'index de z après avoir ajouté la couche à la carte

  const extent = map.getExtent();
  const geometry = [extent.xmin, extent.ymin, extent.xmax, extent.ymax].join(',');

  const service = new FeatureLayerService({
    url: 'https://services.arcgis.com/nGt4QxSblgDfeJn9/ArcGIS/rest/services/Firefly_Grid/FeatureServer/1',
    visible: false
  });

  // Styles de ligne en fonction de la valeur 'Scale'
  const lineStyles: { [key: string]: { lineColor: string; lineWidth: number; lineDash?: number[] } } = {
    '0': { lineColor: 'rgba(0,172,230,0.6)', lineWidth: 2, lineDash: [4, 4] },
    '1': { lineColor: 'rgba(0,172,230,0.15)', lineWidth: 1 },
    '10': { lineColor: 'rgba(0,172,230,0.45)', lineWidth: 1 },
    '20': { lineColor: 'rgba(0,172,230,0.6)', lineWidth: 1 },
    '5': { lineColor: 'rgba(0,172,230,0.35)', lineWidth: 1 }
  };

  service.query({
    geometry: geometry,
    geometryType: 'esriGeometryEnvelope',
    gridQuery: true
  }).then((json: any) => {
    json = JSON.parse(json);
    console.log('features count:', json.features.length);
    const geometries = service.toGeometry(json);

    geometries.forEach((geometry: any) => {
      const scaleValue = geometry.getProperties().Scale;
      const style = lineStyles[scaleValue] || { lineColor: '#000', lineWidth: 1 };
      geometry.setSymbol(style);
    });

    gridLayer.clear(); // Clear existing geometries
    gridLayer.addGeometry(geometries); // Add new geometries
  });
}

useEffect(() => {
  if (!map) return;

  const referencesLayerName = "referencesLayer";
  const gridLayerName = "gridLayer";

  let referencesLayer = map.getLayer(referencesLayerName) as maptalks.TileLayer;
  let gridLayer = map.getLayer(gridLayerName) as maptalks.VectorLayer;

  // Fonction pour actualiser la grille
  const updateGrid = () => {
    if (handleGridOnChange) {
      query(map);
    }
  };

  // Gestion du calque de référence
  if (handleReferenceOnChange) {
    console.log("handleReferenceOnChange: ON");

    if (!referencesLayer) {
      referencesLayer = new maptalks.TileLayer(referencesLayerName, {
        urlTemplate: 'https://server.arcgisonline.com/arcgis/rest/services/Ocean/World_Ocean_Reference/MapServer/tile/{z}/{y}/{x}',
        subdomains: ["a", "b", "c", "d"],
        cssFilter: 'grayscale(100%) brightness(400%)',
        visible: true
      }).setZIndex(10000);

      map.addLayer(referencesLayer);
    } else {
      referencesLayer.show();  // Si déjà ajouté, simplement afficher
    }

    // Assurer que le calque de référence est en dessous du calque de grille
    if (gridLayer) {
      referencesLayer.bringToBack();
      gridLayer.bringToFront();
    }

  } else {
    console.log("handleReferenceOnChange: OFF");
    if (referencesLayer) {
      referencesLayer.hide();  // Utiliser hide() pour éviter les conflits
    }
  }

  // Gestion du calque de grille
  if (handleGridOnChange) {
    console.log("handleGridOnChange: ON");

    // Exécuter la fonction query pour charger les données de la grille
    updateGrid();

    // Assurer que le calque de grille est au-dessus du calque de référence
    if (referencesLayer) {
      gridLayer = map.getLayer(gridLayerName) as maptalks.VectorLayer; // Récupérer la nouvelle instance
      if (gridLayer) {
        gridLayer.bringToFront();
      }
    }

  } else {
    console.log("handleGridOnChange: OFF");
    if (gridLayer) {
      gridLayer.hide();  // Utiliser hide() pour éviter les conflits
    }
  }

  // Réagir aux changements de zoom et de déplacement pour corriger les problèmes d'affichage
  const handleZoomOrMove = () => {
    if (handleGridOnChange) {
      updateGrid();
    }
  };

  map.on('zoomend', handleZoomOrMove);
  map.on('moveend', handleZoomOrMove);

  // Nettoyage des écouteurs d'événements lorsque le composant est démonté ou que les dépendances changent
  return () => {
    map.off('zoomend', handleZoomOrMove);
    map.off('moveend', handleZoomOrMove);
  };

}, [handleReferenceOnChange, handleGridOnChange, map]);










useEffect(() => {
  if (!map) return;


  if (map.getLayer("target")) return;

 
  var layer = new maptalks.VectorLayer('target').setZIndex(2000).setOpacity(0.8);

  layer.setStyle([
    {
      'symbol': [
        {
          'polygonFill': "#1bbc9b",
          'polygonOpacity': 0.5,
          'lineColor': '#000',
          'lineWidth': 2
        },
        {
          'textName' : '{count}',
          'textSize' : 40,
          'textFill' : '#fff'
        }
      ]
    },
  ]);

  layer.addTo(map);

  //if (isDrawingMenuVisible) {

    console.log("isDrawingMenuVisible : true")
    var drawTool = new maptalks.DrawTool({
      mode: 'Circle'
    }).addTo(map).disable();

    drawTool.on('drawend', function (param) {
      console.log(param.geometry);
      layer.addGeometry(param.geometry);
      drawTool.disable();
      //console.log(layer.getLastGeometry().getCenter()["x"]);
      //console.log(layer.getLastGeometry().getCenter()["y"]);
      //console.log(layer.getLastGeometry().toJSON()["radius"]/1e4);
    });


    var toolbar = new maptalks.control.Toolbar({
      items: [
        {
          item: "Target",
          click: function () {
            layer.clear();
            drawTool.setMode('Circle').enable();
            console.log('Circle');
          }
        },
        {
          item: "Clear",
          click: function () {
            layer.clear();
          }
        },
        {
          item: "Close",
          click: function () {
            layer.remove()
            //layerOrderChange = [layer];
            toolbar.remove()
            drawTool.remove()
          }
        }
      ]
    }).addTo(map);

    console.log("toolbar : "+toolbar);

    console.log(map.getMenuItems())



  //} else {
  //  console.log(map.getPanels())
    //map.removeLayer('draw');
    //var toolbar = new maptalks.control.Toolbar({
    //  items: []
    //}).addTo(map).update();

    //console.log(toolbar.show())
    
    
    //console.log(map.getLayers())
  //  drawingTool.disable();
  //console.log("isDrawingMenuVisible : false")
  //}
}, [isDrawingTargetVisible, drawingTool]);


function sliderRendering(layer: maptalks.Layer) {
  if (!mapContainer.current) return;
  if (!map) return;
  var swipe = document.getElementById('myRange') as HTMLInputElement;
  
  if (!swipe) return;
  var renderer = layer.getRenderer();
  var canvasGetter = renderer.getCanvasImage;
  var swipeCanvas = document.createElement('canvas');
  console.log(`Canvas width: ${swipeCanvas.width}, Canvas height: ${swipeCanvas.height}`);
  //override renderer's default method to get layer canvas image
  renderer.getCanvasImage = function () {
    //var dpr = map.getDevicePixelRatio();
    //original layer canvas image
    var layerImage = canvasGetter.call(renderer);
    if (!layerImage || !layerImage.image) {
      return layerImage;
    }
    //drawn width after layer is erased by swipper
    var ctx = renderer.context;

    var width = renderer.canvas.width * (Number(swipe.value) / 100);
    var height = ctx.canvas.height;

    var leftCtx = swipeCanvas.getContext('2d');
    if (!leftCtx)return;

    swipeCanvas.width = ctx.canvas.width;
    swipeCanvas.height = ctx.canvas.height;
    leftCtx.clearRect(0, 0, swipeCanvas.width, swipeCanvas.height);
    leftCtx.drawImage(layerImage.image, 0, 0, width, height, 0, 0, width, height);
    layerImage.image = swipeCanvas;
    //console.log("drawImage")
    return layerImage;
  };

  swipe.addEventListener('input', function () {
    //let layer redraw self in the next frame
    //layer.getRenderer().setToRedraw();
    if (layer.getRenderer()) {
      //console.log(`Renderer Canvas width: ${renderer.canvas.width}, height: ${renderer.canvas.height}`);
      //console.log(`Swipe value: ${swipe.value}`);
      //console.log('Redrawing layer');
      layer.getRenderer().setToRedraw();
    }
  });
}

const addLayer = (id: string) => {
  if (!mapContainer.current) return;
    if (!map) return;
    if (id.includes("wms")) {
      console.log("addLayer:vectorLayerWMS")
      vectorLayerWMS(layerCache.current, map, id, selectedDate, 4000);
    } else {
      console.log("addLayer:newVectorLayer")
      newVectorLayer(layerCache.current, map, id, selectedDate);
    }
    addLayerSliderToIndex(id);
    if (id === "windyLayer"){
      map.getLayers().forEach((element:any) => {
        console.log("Layer:::"+element.id)
      });
    }
    sliderRendering(map.getLayer(id))
  };

  const removeLayer = (id: string) => {
    if (!mapContainer.current) return;
    if (!map) return;
    if (map.getLayer(id)) {
      map.removeLayer(id);
      removeLayerSlider(id);
    }
  };

  const toggleDefaultLayer = (show: boolean) => {
    if (!mapContainer.current) return;
    if (!map) return;
    if (map.getLayer("slider")) {
      map.removeLayer("slider")
    }
    const defaultLayer = map.getLayer("slider") || new maptalks.TileLayer('slider', {
      urlTemplate: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
      subdomains: ['a', 'b', 'c', 'd'],
      visible: true,
      opacity: 1,
      zindex: 4001,
    });

    if (!map.getLayer("slider")) {
      map.addLayer(defaultLayer);
      sliderRendering(map.getLayer("slider"));
    }
    show ? defaultLayer.show() : defaultLayer.hide();
  };

  const errorMessage = (msg: string) => {
    if (!map) return;
    // Créer le panneau avec des options de positionnement
    const textPanel = new maptalks.control.Panel({
      //position: 'absolute', // Utilisez 'absolute' pour le positionnement CSS
      //draggable: true,
      
      content: `<div class=error-message-panel>${msg}</div>`,
      closeButton: true,
    }).addTo(map);
  
    // Assurez-vous que le panneau est ajouté à la carte
    //map.addControl(textPanel);
  };

  useEffect(() => {
    if (!mapContainer.current) return;
    if (!map || !isSliderVisible) return;
    
    console.log("1 visibleLayersSlider : "+visibleLayersSlider)
    console.log("1 sliderLayersIndex.current : "+sliderLayersIndex.current)

    sliderLayersIndex.current.forEach((element: string) => {
      sliderRendering(map.getLayer(element))
    });
    
    
    if (visibleLayersSlider.length === 0 && sliderLayersIndex.current.length === 0) {
      toggleDefaultLayer(true);
      console.log("ZERO layer")
      //console.log("ZERO : visibleLayersSlider : "+visibleLayersSlider)
      //console.log("ZERO : sliderLayersIndex.current : "+sliderLayersIndex.current)
      return;
    }

    if (visibleLayersSlider.length === 0){  
      if (sliderLayersIndex.current.length > 0) {
        var lastId = sliderLayersIndex.current[sliderLayersIndex.current.length-1];
        console.log("POP : "+lastId)
        removeLayer(lastId);
      }
    } else {

      var id = visibleLayersSlider[0];
      if(!sliderLayersIndex.current.includes(id)){
        // Vérification de l'existance du layer dans l'autre partie
        if(map.getLayer(id)){
          visibleLayersSlider = visibleLayersSlider.filter(layer => layer !== id);;
          console.log("Can't add layer twice : "+id);
          errorMessage("Can't add layer twice : "+id);
        } else {
          console.log("AddLayer id: ",id)
          addLayer(id);
        }
      } else {
        removeLayer(id);
        const index = visibleLayersSlider.indexOf(id);
        if (index > -1) {
          visibleLayersSlider.splice(index, 1); // Retirer l'élément du tableau
        }
      }
    }


    if (visibleLayersSlider.length === 0 && sliderLayersIndex.current.length === 0) {
      toggleDefaultLayer(true);
      console.log("ZERO layer bis")
      //console.log("ZERO : visibleLayersSlider : "+visibleLayersSlider)
      //console.log("ZERO : sliderLayersIndex.current : "+sliderLayersIndex.current)
      return;
    }
    //console.log("2 visibleLayersSlider : "+visibleLayersSlider)
    //console.log("2 sliderLayersIndex.current : "+sliderLayersIndex.current)
  }, [map, visibleLayersSlider, isSliderVisible]);


  return (
    <div>
      <div 
        ref={mapContainer} 
        className="maptalk-wrapper" 
      />
      {/*
      <div id="legend"
      style={{
        position: "absolute", 
        bottom: "10px", 
        left: "10px", 
        background: "white", 
        padding: "10px", 
        border: "1px solid black" 
      }}>
      <img id="legend-img" src="" alt="Legend"
      style={{ 
        maxWidth: "200px", // Set your max width
        maxHeight: "200px", // Set your max height
        position: "absolute", 
        bottom: "10px", 
        left: "10px", 
        background: "white", 
        padding: "10px", 
        border: "1px solid black" 
      }}
      />
      </div>
      */}
    </div>
  );
};

export default MapComponent;
