import React, { useState, CSSProperties, useRef } from 'react';
import '../css/MapSlider.css';

interface SwipeControlProps {
    min: number; // Valeur minimale
    max: number; // Valeur maximale
    defaultValue?: number; // Valeur par défaut, optionnelle
    onChange: (value: number) => void; // Fonction à exécuter lors de la modification de la valeur
  }
  
  const SwipeControl: React.FC<SwipeControlProps> = ({ min, max, defaultValue = 50, onChange }) => {
    const [value, setValue] = useState(defaultValue);
    const sliderRef = useRef<HTMLInputElement>(null); // Référence au slider

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = parseInt(event.target.value, 10);
      setValue(newValue);
      //onChange(newValue); // Appelle la fonction onChange passée en prop avec la nouvelle valeur
    };

    

  // Calcule la position du cercle basée sur la valeur du slider
  const circleStyle: CSSProperties = {
    position: 'absolute',
    top: "50vh", // Centré verticalement
    left: `${value}%`, // Position horizontale basée sur la valeur du slider
    transform: 'translate(-44%, -50%)', // Centrer le cercle sur le curseur
    width: '30px', // Taille du cercle
    height: '30px', // Taille du cercle
    borderRadius: '50%', // Rendre le cercle parfaitement rond
    backgroundColor: 'transparent', // Couleur de fond du cercle
    borderColor: '#fff',
    border: '7px solid #fff', // Bordure du cercle
    //zIndex: 1000
    cursor: "pointer",
    
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    const moveHandler = (moveEvent: MouseEvent) => {
      const val: number = Math.max(Math.min(moveEvent.x,window.innerWidth),0);
      const newValue = (val * 100) / window.innerWidth;


      if (sliderRef.current) {
        sliderRef.current.value = newValue.toString(); // Update the value
        setValue(newValue); // Update state to re-render

        // Dispatch an 'input' event to trigger any attached listeners
        const inputEvent = new Event('input', { bubbles: true });
        sliderRef.current.dispatchEvent(inputEvent);

        sliderRef.current.scrollBy({
          left: (newValue - parseFloat(sliderRef.current.value)) * 10, // Adjust the multiplier as needed
          behavior: 'smooth'
        });
      }

    };
    const upHandler = () => {
      document.removeEventListener('mousemove', moveHandler);
      document.removeEventListener('mouseup', upHandler);
    };
    document.addEventListener('mousemove', moveHandler);
    document.addEventListener('mouseup', upHandler);
  };

    return (
      <div className="slider-map-container">
        {/*
        <div className="label-container">
          <div className="label-left">A: 2024 MAR 28</div>
          <div className="label-right">B: 2024 MAR 21</div>
        </div>
        */}
        <div id="sliderCircle" style={circleStyle} onMouseDown={handleMouseDown}></div>
        <input 
          ref={sliderRef}
          id="myRange"
          type="range" 
          className="slider-map" 
          value={value} 
          onChange={handleChange} 
          min={min} 
          max={max}
        />
      </div>
    );
  };
  
  export default SwipeControl;
  