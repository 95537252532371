import React from 'react';

interface ToggleButtonDateOverviewProps {
    toggle: () => void; // Fonction pour gérer le basculement
}

const ToggleButtonDateOverview: React.FC<ToggleButtonDateOverviewProps> = ({ toggle }) => {
    return (
        <button type="button" onClick={toggle} id="toggle-date-overview" className="toggle-button-date" >
      </button>
    );
};

export default ToggleButtonDateOverview;