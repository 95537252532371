import React from 'react';

interface IconButtonProps {
  iconPath: string; // Chemin de l'icône à utiliser
  altText: string;  // Texte alternatif pour l'icône, pour l'accessibilité
  onClick?: () => void; // Optionnel : fonction à exécuter lors du clic sur le bouton
}

const IconButton: React.FC<IconButtonProps> = ({ iconPath, altText, onClick }) => {
  return (
    <button className="custom-button" onClick={onClick}>
      <img src={`${process.env.PUBLIC_URL}/images/icon/${iconPath}`} className="svg-icon" alt={altText} />
    </button>
  );
};

export default IconButton;