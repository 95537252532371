import React, { useState } from 'react';
import '../css/SwitchToggle.css'; // Assurez-vous d'avoir le CSS nécessaire pour le style du commutateur

interface Props {
  isChecked: boolean;
  onChange: () => void;
}
const SwitchToggleReference: React.FC<Props> = ({ isChecked, onChange }) => {

  return (
    <div>
      <label className="switch">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={onChange}
        />
        <span className="slider"></span>
      </label>
      References
    </div>
  );
};

export default SwitchToggleReference;