import React, { useState } from 'react';
import layers from '../data/layers/layersconfig_eOdyn.json';

const LayerSelectorEodyn: React.FC<{ onLayerClick: (layerId: string) => void }> = ({ onLayerClick }) => {
    const [selectedLayers, setSelectedLayers] = useState<string[]>([]);

    const handleLayerClick = (layerId: string) => {
        if (selectedLayers.includes(layerId)) {
            setSelectedLayers(selectedLayers.filter(id => id !== layerId)); // Retire le layer des sélectionnés
        } else {
            setSelectedLayers([...selectedLayers, layerId]); // Ajoute le layer aux sélectionnés
        }
        onLayerClick(layerId); // déclencher des actions sur le clic
    };

    return (
        <div id="sortable-layers" className="layer">
        {layers.map(layer => (
            <div
                key={layer.id}
                className={`additional-layer-option ${selectedLayers.includes(layer.id) ? 'selected' : ''}`}
                onClick={() => handleLayerClick(layer.id)}
            >
                <span>{layer.name}</span>
            </div>
        ))}
        </div>
    );
};

export default LayerSelectorEodyn;
