import React, {} from 'react';

interface ToggleButtonDateCounterLeftProps {
  decrementDay: () => void;
}

const ToggleButtonDateCounterLeft: React.FC<ToggleButtonDateCounterLeftProps> = ({ decrementDay }) => {  return (
    <button type="button" onClick={decrementDay} id="date-counter-left" className="small-custom-button">
        <img src={`${process.env.PUBLIC_URL}/images/icon/001__arrow_left.svg`} className="svg-icon" alt="Icône active" />
    </button>
  );
};

export default ToggleButtonDateCounterLeft;