import React, { useState } from 'react';

interface ToggleButtonDateCounterRightProps {
  incrementDay: () => void;
}

const ToggleButtonDateCounterRight: React.FC<ToggleButtonDateCounterRightProps> = ({incrementDay}) => {
  return (
    <button type="button" onClick={incrementDay} id="date-counter-right" className="small-custom-button">
      <img src={`${process.env.PUBLIC_URL}/images/icon/002__arrow_right.svg`} className="svg-icon" alt="Flèche droite" />
    </button>
  );
};

export default ToggleButtonDateCounterRight;